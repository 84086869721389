  .ant-layout-content {
    background-color: white !important;
    color: #2d3147;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }
  
  .ant-layout-sider-children {
    height: 100vh !important;
  }
  
  .ant-menu {
    background: #FFFFFF !important;
  }
  
  .ant-menu-item {
    color: black !important;
  }

  .site-layout {
    height: 100vh !important;
    overflow: auto !important;
    position: relative !important;
  }  
