.search-section {
  margin: 16px;
}

.search-container {
  width: 100%;
  display: flex;
}

.search-field, .search-field > input {
  background-color: #F7F8FF !important;
  height: 34px !important;
  width: 100% !important;
  border: none !important;
  border-radius: 4px;
}

.search-field, .search-field > input::placeholder {
  color: #374254;
}

.search-icon {
  margin-left: 22px;
}

.gray-text {
  font-size: 14px;
  color: #4B506D;
}

.sort-section {
  height: 100%;
  flex:auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.filter-section {
  background-color: #F7F8FF;
  padding: 13px 8px;
  margin: 0 !important;
}

.filter-dropdown {
  border-radius: 0.375rem !important;
  border: none !important;
  height: 34px !important;
}

.filter-dropdown > .ant-select-selector {
  border: none !important;
}

.filter-dropdown .ant-select-selection-placeholder {
  color: #374254;
}

.filter-dropdown .anticon {

  color: #374254;
}

