.line-items-preview-heading-section {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.preview-line-item {
  font-size: 12px;
  padding: 24px 35px 0;
  margin-top: 30px;
  border-radius: 6px;
  box-shadow: 3px 3px 10px #d3d3d3e1;
  transition: all .3s linear;
  outline: 3px solid transparent;
}

.preview-line-item-main {
  display: flex;
  gap: 24px;
  align-items: center;
}

.preview-line-item:hover {
  outline: 3px solid #3eb3f72f;
}

.preview-line-item-container {
  flex: 1;
  overflow-x: auto;
  overflow: hidden;
}

.h-fit {
  height: fit-content;
}

.preview-line-item-header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.line-item-title {
  font-weight: 700;
  font-size: 16px;
  border: 0;
  padding: 5px 0;
  outline: none;
  width: fit-content;
  border-radius: 4px;
  transition: all .3s ease;
}

.edit {
  padding: 5px 15px;
  background-color: #d3d3d33d;
}

.line-item-rename {
  color: #2C99F7;
  cursor: pointer;
  display: inline-block;
  padding-right: 10px;
}

.line-item-action-icon-container {
  display: flex ;
  gap: 24px;
  justify-content: flex-end;
  height: fit-content;
}

.line-item-action-icon {
  cursor: pointer;
}

.line-item-action-icon-disabled {
  cursor: no-drop;;
}

.preview-line-item-body {
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 1fr 1fr 1fr .5fr .5fr;
  color: black;
}

.preview-line-item-body > div {
  margin-top: 20px;
}

.line-item-subtitle {
  font-size: 14px;
  color: #667080;
}

.preview-line-item-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.preview-line-item-collapse > .anticon {
  cursor: pointer;
  background-color: #D8E2EB;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: -25px;
}

.rotate {
  transform: rotate(180deg);
}

.line-items-preview-footer-section {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
}