.modal-title-line {
  background-color: #F0F0F0;
  height: 3px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.modal-title-width {
  width: 90%;
}

.modal-title-style {
  display: flex;
  justify-content: space-between;
  color: #B1B9C5;
  margin-bottom: 30px;
}

.modal-bottom-line {
  background-color: #F0F0F0;
  height: 3px;
  border-radius: 3px;
  margin-top: 25px;
  margin-bottom: 30px;
}

.advertiser-intput .ant-input {
  background-color: #F6F7FF;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.advertiser-intput .ant-input::placeholder {
  color: #374254;
}



