.nav-container {
  height: 73px;
}

.toggle-bar-container {
  display: flex !important;
  align-items: center !important;
}
  
.ant-layout-header {
  background-color: #363740 !important;
  height: 4.625rem !important;
}

.avatar-expand {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 32px;
  padding-right: 20px !important;
}

.info-icon {
  margin-right: 10px;
}

.ant-dropdown-menu {
  width: 15rem !important;
}

.ant-dropdown-menu-item {
  cursor: pointer;
}

.ant-dropdown-menu-item:hover {
  background-color: whitesmoke !important;
  color: gray !important;
}

.trigger {
  color: white !important;
  margin-left: 15px;
  font-size: 27px;
  transition: all .3s linear;
}

.ant-avatar {
  cursor: pointer;
  background: #3399FF;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.base-header {
  height: 7rem !important;
  position: sticky !important;
  z-index: 99 !important;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.base-header .ant-breadcrumb {
  background-color: #445CFF !important;
}