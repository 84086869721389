.creative-footer-form-group {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}

.creative-add-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}

.skip-button {
  margin-right: 2rem !important;
}

.form-button-group-format > .empty > .ant-form-item {
  margin-bottom: 0 !important;
}

.form-button-group-format > .creative-footer-form-group > .ant-form-item {
  margin-bottom: 0 !important;
}

.transition-max-h {
  -webkit-transition: max-height .7s linear; 
  -moz-transition: max-height .7s linear; 
  -ms-transition: max-height .7s linear; 
  -o-transition: max-height .7s linear; 
  transition: max-height .7s linear; 
  overflow: hidden;
  max-height: 550px;
}

.gutter-row .ant-row {
  margin-bottom: 16px !important;
}