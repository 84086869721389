/* Google Font  */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*-----------------------------------*/
/*?   Scroll-Bar Styling Properties  */
/*-----------------------------------*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #80BEF9;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #45a3fa;
}

.btn-primary-form {
  background: #3E99F4 !important
}

.ant-btn-secondary {
  background: #EBEDEF !important;
}

input::placeholder, textarea::placeholder, .ant-select-selection-placeholder, 
.ant-table-thead .ant-table-cell {
  color: #404040 !important;
}

.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eee
}

/*-----------------------------------*/
/*?  Right to Left  FadeIn Animation  */
/*-----------------------------------*/
.right-to-left-fade-in {
  animation-name: rightToLeftFadeIn;
  animation-duration: .4s;
  animation-timing-function: linear;
}

@keyframes rightToLeftFadeIn {
  0% {
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Page */
.page-heading {
  font-size: 24px;
  font-weight: 500;
  padding: 26px 0 0 19px;
  color: #252733;
}

/*-----------------------------------*/
/*?  Text Color Styling Properties   */
/*-----------------------------------*/
.text-inactive { color: #c0c0c0; }
.text-primary { color: #2C99F7; }

/*-----------------------------------*/
/*?    Cursor Styling Properties     */
/*-----------------------------------*/
.cursor-pointer { cursor: pointer; }
.cursor-not-allowed { cursor: not-allowed; }

/*-----------------------------------*/
/*? Margin-Padding Styling Properties */
/*-----------------------------------*/
.mr { margin-right: 5px; }
.mr-2 { margin-right: 10px; }
.p-2 { padding: 10px; }

/*-----------------------------------*/
/*? Height-Width Styling Properties  */
/*-----------------------------------*/
.w-screen { width: 100vw; }
.h-screen { height: 100vh; }
.screen { height: 100vh; width: 100vw;}
.max-h-0 { max-height: 0px; }

/*-----------------------------------*/
/*?   Debugging Style Properties     */
/*-----------------------------------*/
.debug-c { background-color: red !important; }
.debug-b { border: 2px solid red !important; }

/*-----------------------------------*/
/*?  Allignment Styling Properties   */
/*-----------------------------------*/
.v-center {
  display: flex;
  align-items: center;
}
.h-center {
  display: flex;
  justify-content: center;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-----------------------------------*/
/*?    Others Styling Properties     */
/*-----------------------------------*/
.rotate-90 { rotate: 90deg !important; }

.gap { gap: 5px; }
.gap-2 { gap: 10px; }