.campaign-table {
  margin-top: 0px !important;
}

.campaign-table * {
  border-color: #D8E2EB !important;
}

/* .campaign-table .ant-table-content {
  transform: rotateX(180deg);
} */

.campaign-table .ant-table-content table{
  /* transform: rotateX(180deg); */
  font-size: 12px;
}

.campaign-table .ant-select-selector {
  height: 32px !important;
}

.campaign-table thead * {
  background-color: #FFFFFF !important;
}

.campaign-table th {
  height: 46px !important;
  background-color: #FFFFFF !important;
  color: #B1B9C5 !important;
  font-weight: 500 !important;
}

.campaign-table .ant-table-row-level-1 .ant-table-cell {
  background-color: #F0F4F7;
}

.campaign-table .ant-table-row-level-1:hover .ant-table-cell {
  background-color: #F0F4F7;
}

.campaign-table .ant-table-expanded-row * {
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 5px !important;
}

.campaign-table .ant-table-cell > .ant-dropdown-trigger {
  height: 22px !important;
  cursor: pointer;
}

.campaign-table .ant-dropdown-menu-title-content > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.campaign-table .ant-table-row-expand-icon-cell {
  padding: 0px !important;
}

.osi-box-container {
  display: flex;
  gap: 1px;
  background-color: #DFE0EB;
  border: 1px solid #DFE0EB;
  border-radius: 7px;
  overflow: hidden;
}

.osi-box {
  height: 13px;
  width: 11px;
}

.platform-icon {
  height: 27px;
  width: 27px;
}

.platform-icon-dropdown {
  height: 17px;
  width: 17px;
  margin-right: 5px;
}

.dot {
  display: inline-block;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  margin-right: 5px;
}

.campaign-dropdown-icon {
  font-size: 17px;
  transition: all .5s ease;
}

.rotate-180 {
  transform: rotate(-180deg);
}

.pagination {
  display: flex !important;
  justify-content: center !important;
  margin: 16px 0px !important;
}

.bar {
  display: inline-block;
  width: 5px;
  height: 50px;
  margin-top: 5px !important;
  background-color: #B1B9C5 !important;
}

.star {
  font-size: '17px'
}

.expanded-row td {
  background-color: #D8E2EB !important;
}