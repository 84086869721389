.base-breadcrumb {
  box-shadow: 0px 5px 5px lightgray;
}

.base-breadcrumb {
  box-shadow: 0px 5px 5px lightgray;
  color: #F0F4F7 !important;
}

.ant-breadcrumb {
  background-color: #455DFB !important;
  height: 2.4rem !important;
  display: flex !important;
  align-items: center !important;
}

.ant-breadcrumb-link {
  color: #F0F4F7 !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.ant-breadcrumb ol {
  margin-left: 1rem !important;
}

.ant-breadcrumb-separator {
  color: white !important;
}

.breadcrumb-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.breadcrumb-text-color {
  color: white;
}