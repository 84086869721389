.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.blur {
  background-color: #ffffff88;
}

.screen-size {
  width: 100vw;
  height: 100vh;
}

.container-size {
  width: 100%;
  height: 100%;
}