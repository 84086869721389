.ant-radio-group {
  width: 100%;
}

.custom-radio-warapper  .ant-radio-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 2px 2px 10px rgba(17, 17, 17, 0.1); 
  border-radius: 6px;
  padding-left: 10px;
}

.custom-radio-warapper .ant-radio-wrapper-checked {
  border: 1px solid #2C99F7;
}

.custom-radio-warapper .ant-radio-wrapper-disabled {
  background: whitesmoke;
}