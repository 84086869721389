.creative-table {
  border: 1px solid #D8E2EB;
  border-radius: 4px;
  margin-bottom: 45px;
}

.creative-table-menu-container {
  background-color: #F0F4F7;
  padding: 9px;
  border-radius: 3px 3px 0 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.creative-table-menu-container > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.creative-table-menu-container .ant-switch {
  margin-left: 7px;
}

.creative-table th {
  border-radius: 0px !important;
  background-color: #F0F4F7 !important;
}

.creative-item-edit-icon {
  font-size: 17px;
  color: #404040;
  cursor: pointer;
  transition: all .3s linear;
}

.creative-item-edit-icon:hover {
  color: #2C99F7;
}