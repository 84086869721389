.notfoundClass {
    min-height: 500px;
    padding-top: 85px;
    text-align: center;
}

.notfound-icon {
  color: black;
  font-size: 128px;
}

.notfound-message {
    font-size: 20px;
    color: #404040;
    font-weight: 600;
    line-height: 120px;
    margin: 15px 0;
}
.notfound-link {
    color: blue;
    font-size: 16px;
    font-weight: 500;
}