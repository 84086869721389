.tooltip-container {
  padding: 12px 16px 20px 16px;
}

.tooltip-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.tooltip-yellow-dash {
  background-color: #FEC400;
  height: 3px;
  width: 30px;
}

.tooltip-close-btn {
  color: #C1C7C9;
  cursor: pointer;
  transition: all .3s linear;
  padding: 3px;
  border-radius: 50%;
}

.tooltip-close-btn:hover {
  color: #FFFFFF;
  background: #b0b3b4;
}

.bundle-targeting-input {
  overflow: hidden !important;
  margin-bottom: 14px;
  border-radius: 5px 0 0 5px !important;
}

.bundle-targeting-input .ant-input-affix-wrapper {
  border: 0 !important;
  background-color: #F7F8FF !important;
}

.bundle-targeting-input .ant-input-prefix {
  margin-left: 10px !important;
}

.bundle-targeting-input input {
  height: 40px !important;
  border: 0 !important;
  color: #248ef1 !important;
  background-color: #F7F8FF;
  padding-left: 13px !important;
}

.bundle-targeting-input .ant-input-group-addon {
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
}

.bundle-targeting-common-btn .anticon {
  color: #FFFFFF;
  padding: 13px 12px;
  cursor: pointer;
  background-color: #80BEF9;
}

.bundle-targeting-upload .ant-upload {
  width: 100% !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.bundle-targeting-upload input {
  cursor: pointer !important;
}

.bundle-targeting-upload .ant-upload-list-item {
  display: none !important;
}

.bundle-targeting-drag-wrapper {
  padding: 12px;
  background-color: #F7F8FF;
  border-radius: 4px;
  margin-bottom: 14px;
  transition: all .4s ease;
  outline: 3px solid transparent;
}

.bundle-targeting-drag-wrapper:hover {
  outline: 3px solid #80bef957;
}

.bundle-targeting-upload .ant-upload-drag .ant-upload {
  background-color: #FFFFFF !important;
}

.bundle-targeting-upload-drag-icon .anticon {
  font-size: 40px;
}

.bundle-targeting-plus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  padding: 0 12px;
  cursor: pointer;
  background-color: #80BEF9;
  height: inherit;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  transform: translateX(-1px);
  margin-bottom: 14px;
}
