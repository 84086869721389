.logo {
  height: 7rem !important;
  margin: 0px !important;
  background-color: #363740 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.tiny-logo {
  width: 40px !important;
  height: 29px !important;
  cursor: pointer !important;
}

.full-logo {
  width: 172px !important;
  height: 30px !important;
  cursor: pointer !important;
}

.ant-layout-sider-dark {
  max-width: 16rem !important;
  width: 16rem !important;
  flex: none !important;
  background-color: #FFFFFF !important;
  box-shadow: 4px 4px 4px #00000026 !important;
}

.collapsed-sidebar {
  width: 0px !important;
}

.ant-menu-item-selected {
  background-color: #e6f7ff !important;
}

.ant-menu-item-selected > .ant-menu-title-content {
  color: #2C99F7 !important;
}

.ant-menu-item-selected > .ant-menu-item-icon {
  color: #2C99F7 !important;
}

.ant-menu-item-active > .ant-menu-title-content:hover {
  color: #2C99F7 !important;
}

.ant-menu-item-active > .ant-menu-item-icon {
  color: #2C99F7 !important;
}

.sidebar {
  z-index: 99;
  position: fixed;
}

.sidebar .ant-menu * {
  background-color: transparent !important;
  transition: all .1s linear !important;
}

.sidebar .ant-menu-item {
  color: #374254;
  font-size: 16px;
  font-weight: 500;
  display: flex !important;
  align-items: center !important;
}

.sidebar .ant-menu-submenu {
  font-size: 16px;
  font-weight: 500;
}
