
.container {
  height: 82%;
  /* border: 1px solid red; */
}

.campaign_title{
  padding-bottom: 26px;
}

.campaign_title p {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
}

.create-campaign-container {
  padding: 50px 30px 90px;
  overflow-y: auto;
  height: calc(100vh - 112px);
}

.form-button-group-format {
  display: flex !important;
  justify-content: space-between !important;
}

.form-button-group-format > .ant-form-item {
  margin-bottom: 0 !important;
}
