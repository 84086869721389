.ant-picker {
    width: 100% !important;
}

.number-input-width {
    width: 100% !important;
}

.currency-select-without-label {
    margin-top: 40px !important;
}

.campaign-form {
    display: flex !important;
    height: 100% !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

