.line-0 {
  background: #F0F0F0;
  height: 5px;
  border-radius: 3px;
  margin-top: 5px;
}

.line-1 {
  background: #80BEF9;
  height: 5px;
  border-radius: 3px;
  margin-top: 5px;
}

.line-2 {
  background: #0073D7;
  height: 5px;
  border-radius: 3px;
  margin-top: 5px;
}

.line-3 {
  background: #003A8C;
  height: 5px;
  border-radius: 3px;
  margin-top: 5px;
}

.disable-tab {
  color: #B1B9C5;
}

.text-bold {
  font-weight: 700;
}