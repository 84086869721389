.ant-collapse {
  background-color: white !important;
  border: none !important;
}

.ant-collapse-item {
    margin-bottom: 20px !important;
    box-shadow: 2px 2px 10px rgba(17, 17, 17, 0.1) !important;
}

#geo .ant-collapse-content {
  display: none !important;
}

#brandsafety .ant-collapse-content {
  display: none !important;
}

.ant-collapse-item-active {
  background-color: #EAF5FE !important;
  border: 1px solid #D7EBFD !important;
}

.ant-collapse-item {
  border: 1px solid rgba(17, 17, 17, 0.1) !important;
  border-radius: 6px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.sub-item {
  cursor: pointer !important;
  padding-left: 16px !important;
}

.sub-item:hover {
  color: #2C99F7;
  background-color: #F6FBFF;
}

.sub-item-active {
  color: #2C99F7;
  background-color: #F6FBFF;
}

.selected-list {
  border: 1px solid #dfe0eb;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px !important;
}

.selected-list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #dfe0eb;
  cursor: pointer;
}

.selected-list-head > span {
  font-weight: 700;
}

.selected-list .anticon {
  margin-left: 10px;
  padding: 5px;
  border-radius: 50%;
  transition: all .3s ease;
}

.selected-list .anticon:hover {
  background-color: #dfe0eb;
}

.empty-field {
  height: 75px;
  color: #9FA2B4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-top: 1px solid #dfe0eb;
}

.selected-list-body {
  max-height: 135px;
  overflow-y: auto;
}

.targeting-panel .ant-collapse-header-text {
  font-size: 16px !important;
  color: #374254 !important;
}

.targeting-panel .anticon-plus-circle {
  color: #374254 !important;
}

.collapse-icon {
  font-size: 22px !important;
  transition: all .3s linear;
}

.rotate-135 {
  rotate: 135deg;
}

.check-mark {
  position: absolute;
  top: 14px;
  left: -30px;
  height: 22px;
  width: 22px;
}
